import styled from 'styled-components';

export const UserListContainer = styled.div`
  padding: 12px;
  background-color: #212121;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #353535;
  width: 100%;
  margin: 4px 0;
`;

export const UserListItem = styled.div`
  display: flex;
  padding: 12px;
  cursor: pointer;
  align-items: center;
  gap: 8px;
  min-width: 268px;
  border-radius: 12px;
  &:hover {
    background-color: #353535;
  }
`;

export const UserListContent = styled.div`
  display: grid;
  grid-gap: 4px;
`;

export const UserListTitle = styled.p`
  font: 0.875rem/1.25rem Onest-Medium;
  color: #fff;
`;

export const UserListIconContainer = styled.div``;

export const UserListText = styled.div`
  color: ${(props) => props.theme.palette.text.t2};
`;

export const UserLisMembershipStatusContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 12px 8px;

  p {
    font: 0.875rem/1.125rem Onest-Medium;
    color: #fff;
  }
`;
