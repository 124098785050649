import dynamic from 'next/dynamic';

import { memo } from 'react';
import UserPopoverList from '../../UserPopoverList/UserPopoverList';
import HeaderUserIcon from './HeaderUserIcon';

const BasicPopover = dynamic(() => import('../../Popover/Popover'), {
  ssr: false,
});

const HeaderLoggedIn = () => {
  return (
    <BasicPopover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      boxPadding={0}
      button={<HeaderUserIcon />}
      sx={{ borderRadius: '16px' }}
    >
      <UserPopoverList />
    </BasicPopover>
  );
};

export default memo(HeaderLoggedIn);
