import { useRouter } from 'next/router';
import { COLORS } from '../../../constants/colors';
import { saveEventV3 } from '../../../utils/eventTracking';
import { MaskIcon } from '../../Icons/Icons';
import { IconContainer, UserContainer } from '../HeaderStyle';

const HeaderUserIcon = () => {
  const router = useRouter();

  return (
    <UserContainer>
      <IconContainer>
        <MaskIcon
          url="/icons/profile-24.svg"
          selected={true}
          margin="0 0 0 0"
          onClick={() => {
            saveEventV3({
              category: 'header',
              action: 'click',
              label: 'user profile dropdown',
              properties: '',
              value: [],
              from: router,
            });
          }}
          height="24px"
          width="24px"
          color={COLORS.white}
        />
      </IconContainer>
      {/* <UserName>{user && user.first_name ? user.first_name : null}</UserName> */}
    </UserContainer>
  );
};

export default HeaderUserIcon;
