import { useRouter } from 'next/router';
import React, { memo, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  Divider,
  UserLisMembershipStatusContainer,
  UserListContainer,
  UserListContent,
  UserListIconContainer,
  UserListItem,
  UserListTitle,
} from './UserPopoverListStyle';

import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { useESim } from '../../contexts/ESimContext';
import { useLocale } from '../../contexts/LocalizationContext';
import { logoutUserServer } from '../../redux/userSlice';
import { saveEventV3 } from '../../utils/eventTracking';
import { BackgroundIcon, MaskIcon } from '../Icons/Icons';
import UserPopoverListItem from './component/UserPopoverListItem';

const UserPopoverList = () => {
  const router = useRouter();

  const { messages } = useLocale();
  const {
    my_profile_msg,
    my_library_msg,
    logout_msg,
    my_orders_msg,
    seller_dashboard_msg,
    help_center_msg,
    esim_header_my_esims_msg,
    esim_header_my_orders_msg,
    esim_header_my_profile_msg,
  } = messages || {};

  const { isESim } = useESim();
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { cleverTap } = useAnalytics();

  const listItems = useMemo(
    () => [
      {
        route: '/user/profile',
        title: my_profile_msg ?? '',
        iconSrc: '/icons/profile-24.svg',
      },
      {
        route: '/user/library?type=all',
        title: my_library_msg ?? '',
        iconSrc: '/icons/controller-24.svg',
      },
      {
        route: '/user/wallet',
        title: 'Wallet',
        description: 'View your wallet',
        iconSrc: '/icons/wallet/wallet.svg',
      },
      {
        route: '/user/orders?type=all',
        title: my_orders_msg ?? '',
        iconSrc: '/icons/bag-24.svg',
      },
      {
        route: '/user/membership',
        title: 'Driffle plus',
        iconSrc: '/logo/driffle-plus-icon-24.svg',
        iconColor: 'fixed',
      },
      {
        route: '/user/selling/dashboard',
        title:
          user?.user?.storeDetails?.store_status === 1
            ? seller_dashboard_msg ?? ''
            : '',
        iconSrc: '/icons/store-24.svg',
      },
      {
        route: '/support',
        title: help_center_msg ?? '',
        iconSrc: '/icons/support-24.svg',
      },
    ],
    [
      help_center_msg,
      my_library_msg,
      my_orders_msg,
      my_profile_msg,
      seller_dashboard_msg,
      user?.user?.storeDetails?.store_status,
    ]
  );

  const logoutClicked = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      saveEventV3({
        category: 'header',
        action: 'click',
        label: 'user_dropdown',
        properties: 'logout',
        value: [],
        from: router,
      });
      await dispatch(logoutUserServer(router, cleverTap)).finally(() => {
        router.replace({
          ...router,
          query: {
            ...router.query,
            redirect: 0,
          },
        });
      });
    },
    [cleverTap, dispatch, router]
  );

  const esimListItem = useMemo(
    () => [
      {
        route: '/esim/account/library?order_status=my_esim',
        title: esim_header_my_esims_msg ?? 'My eSIMs',
        description: '',
        iconSrc: '/icons/esims/sim_logo.svg',
      },
      {
        route: '/esim/account/orders?order_status=all_orders',
        title: esim_header_my_orders_msg ?? 'My Orders',
        description: '',
        iconSrc: '/icons/esims/order-icon.svg',
      },
      {
        route: '/esim/account/profile',
        title: esim_header_my_profile_msg ?? 'My Profile',
        description: '',
        iconSrc: '/icons/profile-24.svg',
      },
    ],
    [
      esim_header_my_esims_msg,
      esim_header_my_profile_msg,
      esim_header_my_orders_msg,
    ]
  );

  const checkWallet = (item: any) => {
    return item.title === 'Wallet' ? !!user?.user?.customerWallet?.id : true;
  };

  return (
    <UserListContainer>
      {isESim ? null : user.subscription === 'active' &&
        !user.user?.storeDetails?.store_name ? (
        <>
          <UserLisMembershipStatusContainer>
            <BackgroundIcon
              url={'/logo/plus-logo-with-bg.svg'}
              width="52px"
              height="24px"
              margin="0"
            />
            <p>membership is active</p>
          </UserLisMembershipStatusContainer>
          <Divider />
        </>
      ) : null}
      {isESim
        ? esimListItem.map((cur, index) => (
            <UserPopoverListItem key={index} {...cur} />
          ))
        : listItems
            .filter(checkWallet)
            .map((cur, index) => <UserPopoverListItem key={index} {...cur} />)}
      <Divider />
      <UserListItem
        style={
          isESim
            ? {
                width: '100%',
                minWidth: '183px',
                padding: '12px 8px',
                gap: '8px',
                alignItems: 'center',
              }
            : {}
        }
        onClick={logoutClicked}
      >
        <UserListIconContainer>
          <MaskIcon
            url={'/icons/logout-purple.svg'}
            color={'#fff'}
            width="20px"
            height="20px"
            selected={true}
            margin="0"
          />
        </UserListIconContainer>
        <UserListContent>
          <UserListTitle>{logout_msg}</UserListTitle>
        </UserListContent>
      </UserListItem>
    </UserListContainer>
  );
};

export default memo(UserPopoverList);
