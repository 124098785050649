import router from 'next/router';
import React, { memo } from 'react';
import { saveEventV3 } from '../../../utils/eventTracking';

import { useESim } from '../../../contexts/ESimContext';
import { useAppSelector } from '../../../redux/hooks';
import { BackgroundIcon, MaskIcon } from '../../Icons/Icons';
import {
  UserListContent,
  UserListItem,
  UserListTitle,
} from '../UserPopoverListStyle';

interface UserPopoverListItemPropsI {
  route: string;
  iconSrc: string;
  title: string;
  iconColor?: string | undefined;
}

const UserPopoverListItem: React.FC<UserPopoverListItemPropsI> = ({
  route,
  iconSrc,
  title,
  iconColor,
}) => {
  const { isESim } = useESim();
  const user = useAppSelector((state) => state.user);

  return title === '' ||
    (route === '/user/membership' &&
      (user.subscription === 'no' ||
        user.user?.storeDetails?.store_name)) ? null : (
    <UserListItem
      style={
        isESim
          ? {
              width: '100%',
              minWidth: '183px',
              padding: '12px 8px',
              gap: '8px',
              alignItems: 'center',
            }
          : {}
      }
      onClick={() => {
        saveEventV3({
          category: 'header',
          action: 'click',
          label: 'user_dropdown',
          properties: title,
          value: [route],
          from: router,
        });
        router.push(route);
      }}
    >
      {iconColor === 'fixed' ? (
        <BackgroundIcon url={iconSrc} width="20px" height="20px" margin="0" />
      ) : (
        <MaskIcon
          url={iconSrc}
          color={'#fff'}
          width="20px"
          height="20px"
          selected={true}
          margin="0"
        />
      )}

      <UserListContent>
        <UserListTitle>{title}</UserListTitle>
      </UserListContent>
    </UserListItem>
  );
};

export default memo(UserPopoverListItem);
