import { useRouter } from 'next/router';
import { memo, useEffect, useRef, useState } from 'react';
import { MaskIcon } from '../Icons/Icons';
// import SearchAutocomplete from '../SearchAutocomplete/SearchAutocomplete';
import {
  InputAutocompleteContainer,
  InputBox,
  InputContainer,
  SlashIconContainer,
  SlashText,
} from './ProductSearchInputStyle';

import dynamic from 'next/dynamic';
import { COLORS } from '../../constants/colors';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { useLocale } from '../../contexts/LocalizationContext';
import useSearch from '../../hooks/search/useSearch';
import { saveEventV3 } from '../../utils/eventTracking';
import { WE_USER_EVENTS_SEARCH_RESULTS } from '../../utils/we';

const SearchAutocomplete = dynamic(
  () => import('../SearchAutocomplete/SearchAutocomplete'),
  {
    ssr: true,
  }
);

const RecentSearches = dynamic(
  () => import('../RecentSearches/RecentSearches'),
  {
    ssr: true,
  }
);

interface Props {
  type: 'web' | 'mobile' | null;
}

const ProductSearchInput = (props: Props) => {
  const router = useRouter();

  const { messages } = useLocale();
  const { search_games_gift_msg, search_msg } = messages || {};

  const webWrapperRef = useRef<HTMLDivElement>(null);
  const mobileWrapperRef = useRef<HTMLDivElement>(null);
  const webInputRef = useRef<HTMLInputElement>(null);
  const mobInputRef = useRef<HTMLInputElement>(null);

  const [placeholderValue, setPlaceholderValue] = useState<string>(
    search_games_gift_msg ?? ''
  );
  const [cursor, setCursor] = useState(0);
  const [displayAutocomplete, setDisplayAutocomplete] =
    useState<boolean>(false);
  const [displayRecentSearches, setDisplayRecentSearches] =
    useState<boolean>(false);

  const { results, searchText, searchedText, setSearchText } = useSearch();
  const { cleverTap } = useAnalytics();

  const goToStore = (e?: any) => {
    setDisplayAutocomplete(false);
    if (e?.target?.value) {
      WE_USER_EVENTS_SEARCH_RESULTS(
        {
          pageUrl: window.location.href,
          resultLink: `/store?q=${e.target.value}`,
          searchQuery: e.target.value,
        },
        cleverTap
      );
      saveEventV3({
        category: 'header',
        action: 'click',
        label: 'search see more',
        properties: `/store?q=${e.target.value}`,
        value: [e.target.value],
        from: router,
      });
      router.push(`/store?q=${e.target.value}`);
    } else {
      if (webInputRef.current?.value) {
        WE_USER_EVENTS_SEARCH_RESULTS(
          {
            pageUrl: window.location.href,
            resultLink: `/store?q=${webInputRef.current.value}`,
            searchQuery: webInputRef.current.value,
          },
          cleverTap
        );
        router.push(`/store?q=${webInputRef.current.value}`);
      } else if (mobInputRef.current?.value) {
        WE_USER_EVENTS_SEARCH_RESULTS(
          {
            pageUrl: window.location.href,
            resultLink: `/store?q=${mobInputRef.current.value}`,
            searchQuery: mobInputRef.current.value,
          },
          cleverTap
        );
        router.push(`/store?q=${mobInputRef.current.value}`);
      } else {
        router.push('/store');
      }
    }
    setDisplayAutocomplete(false);
  };

  const handleClickOutside = (e: any) => {
    if (props.type === 'web') {
      const { current: wrap } = webWrapperRef;
      if (wrap && !wrap.contains(e.target)) {
        setDisplayAutocomplete(false);
        setDisplayRecentSearches(false);
      }
    } else {
      const { current: wrap } = mobileWrapperRef;
      if (wrap && !wrap.contains(e.target)) {
        setDisplayAutocomplete(false);
        setDisplayRecentSearches(false);
      }
    }
    if (router.query.search) {
      router.query.search = '0';
      router.push(router);
    }
  };

  const handleOnKeyDown = (e: any) => {
    if (e.keyCode === 38) {
      if (cursor === 0) {
        return;
      } else {
        setCursor(cursor - 1);
      }
    } else if (e.keyCode === 40 && results) {
      if (cursor === results.list.length - 1) {
        return;
      } else {
        setCursor(cursor + 1);
      }
    } else if (e.keyCode === 13) {
      goToStore(e);
      // Enter key
    }
  };

  const handleSlashSearch = (e: KeyboardEvent) => {
    setTimeout(() => {
      if (
        e.key === '/' &&
        webInputRef &&
        webInputRef.current &&
        router.pathname !== '/become-affiliate'
      ) {
        webInputRef.current.focus();
        setDisplayAutocomplete(true);
      }
    }, 100);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('keypress', handleSlashSearch);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('keypress', handleSlashSearch);
    };
  }, []);

  useEffect(() => {
    if (router.query.search === '1') {
      webInputRef && webInputRef.current && webInputRef.current.focus();
      setDisplayAutocomplete(true);
    }
  }, [router]);

  // useEffect(() => {
  //   if (width) {
  //     if (width <= 1230) {
  //       setPlaceholderValue(search_msg);
  //     } else {
  //       setPlaceholderValue(search_games_gift_msg);
  //     }
  //   }
  // }, [width]);

  return (
    <>
      {
        props.type === 'web' ? (
          <InputAutocompleteContainer ref={webWrapperRef}>
            <InputContainer>
              <SlashIconContainer>
                <SlashText>
                  <MaskIcon
                    url="/icons/search-24.svg"
                    height="24px"
                    width="24px"
                    selected={true}
                    color={COLORS.black}
                    margin={'0'}
                  />
                </SlashText>
              </SlashIconContainer>
              <InputBox
                placeholder={placeholderValue}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  // debounceOnChange(e.target.value);
                }}
                // value={searchText}
                onClick={() => {
                  saveEventV3({
                    category: 'header',
                    action: 'click',
                    label: 'search',
                    properties: '',
                    value: [],
                    from: router,
                  });

                  setDisplayAutocomplete(true);
                }}
                onKeyDown={handleOnKeyDown}
                onFocus={() => setDisplayRecentSearches(true)}
                ref={webInputRef}
                id="web-search-input"
                autoComplete="off"
              />

              {/* <SearchIconContainer onClick={goToStore}>
              <SearchIconWrapper displayAutocomplete={displayAutocomplete}>
                <MaskIcon
                  url="/icons/search-black.svg"
                  selected={true}
                  height="15px"
                  width="15px"
                  margin="0 0 0 0"
                  color={displayAutocomplete ? 'white' : 'black'}
                />
              </SearchIconWrapper>
            </SearchIconContainer> */}
            </InputContainer>
            {searchedText === '' && (
              <RecentSearches
                display={displayRecentSearches}
                setDisplayRecentSearches={setDisplayRecentSearches}
              />
            )}
            {results ? (
              <SearchAutocomplete
                display={displayAutocomplete}
                cursor={cursor}
                products={results.list}
                searchText={searchedText}
                clickOutside={setDisplayAutocomplete}
                goToStore={goToStore}
                recommendationId={results.recommId}
              />
            ) : null}
          </InputAutocompleteContainer>
        ) : null

        // (
        //   <>
        //     <div
        //       style={{
        //         position: 'absolute',
        //         left: '15px',
        //         pointerEvents: 'none',
        //       }}
        //       id="search-icon-mobile"
        //     >
        //       <SearchIcon />
        //     </div>
        //     <MobileSearchInput
        //       type="text"
        //       name="search"
        //       className={styles.extand}
        //       placeholder={search_msg}
        //       onChange={(e) => {
        //         setSearchText(e.target.value);
        //         // debounceOnChange(e.target.value);
        //         setDisplayAutocomplete(true);
        //       }}
        //       value={searchText}
        //       onClick={() => setDisplayAutocomplete(true)}
        //       onKeyDown={handleOnKeyDown}
        //       autoComplete="off"
        //       onFocus={() => setDisplayRecentSearches(true)}
        //       ref={mobInputRef}
        //       id="mobile-search-input"
        //     />
        //     <IconContainer
        //       id="cross-icon"
        //       onClick={() => {
        //         setDisplayAutocomplete(false);
        //       }}
        //     >
        //       <CrossIcon />
        //     </IconContainer>
        //     <div ref={mobileWrapperRef}>
        //       {searchedText === '' && (
        //         <MobileInputAutocompleteContainer>
        //           <div style={{ minHeight: 'fit-content' }}>
        //             <RecentSearches
        //               display={displayRecentSearches}
        //               setDisplayRecentSearches={setDisplayRecentSearches}
        //             />
        //           </div>
        //         </MobileInputAutocompleteContainer>
        //       )}

        //       {results ? (
        //         <MobileInputAutocompleteContainer>
        //           <SearchAutocomplete
        //             display={displayAutocomplete}
        //             cursor={cursor}
        //             products={results.list}
        //             searchText={searchedText}
        //             clickOutside={setDisplayAutocomplete}
        //             goToStore={goToStore}
        //             mobileInputRef={mobInputRef}
        //             recommendationId={results.recommId}
        //           />
        //         </MobileInputAutocompleteContainer>
        //       ) : null}
        //     </div>
        //   </>
        // )
      }
    </>
  );
};

export default memo(ProductSearchInput);
