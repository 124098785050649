import { useRouter } from 'next/router';
import { memo } from 'react';
import { useLocale } from '../../../contexts/LocalizationContext';
import { useAppDispatch } from '../../../redux/hooks';
import {
  openLoginModalRedux,
  openRegisterModalRedux,
} from '../../../redux/modalSlice';
import { saveEventV3 } from '../../../utils/eventTracking';
import Button from '../../NewButton/Button';
import { LoggedOutButtonsContainer, LoginBtnWrapper } from '../HeaderStyle';

const HeaderLoggedOut = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const { messages } = useLocale();
  const { login_msg, signup_msg } = messages || {};

  return (
    <>
      <LoggedOutButtonsContainer>
        <LoginBtnWrapper>
          <Button
            buttonTheme="grey"
            height="48px"
            width="120px"
            onClick={() => {
              saveEventV3({
                category: 'header',
                action: 'click',
                label: 'login',
                properties: '',
                value: [],
                from: router,
              });
              dispatch(openLoginModalRedux());
            }}
            ariaLabel="login"
          >
            {login_msg}
          </Button>
        </LoginBtnWrapper>
        <Button
          buttonTheme="primary"
          onClick={() => {
            saveEventV3({
              category: 'header',
              action: 'click',
              label: 'signup',
              properties: '',
              value: [],
              from: router,
            });
            dispatch(openRegisterModalRedux());
          }}
          height="48px"
          width="120px"
          ariaLabel="sign up"
        >
          {signup_msg}
        </Button>
      </LoggedOutButtonsContainer>
    </>
  );
};

export default memo(HeaderLoggedOut);
