import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const InputAutocompleteContainer = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  max-width: 920px;
  margin-left: 64px;
  flex: 1;
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  // box-shadow: ${(props) => props.theme.shadows.s2};
  z-index: 12;
  @media (mmmax-width: ${WIDTH.desktopMin}) {
    // margin-right: 60px;
    // margin-left: 80px;
  }
  @media (max-width: ${WIDTH.laptopMid}) {
    // margin-right: 20px;
    // margin-left: 20px;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 0;
    border-radius: 0;
    padding: 0 16px;
    background: ${COLORS.headerBg};
  }
`;
interface Props {
  active: boolean;
}
export const InputContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  transition: all 0.3s ease;
  width: 100%;

  @media (max-width: ${WIDTH.tabletMax}) {
    margin-bottom: 16px;
    background: ${COLORS.headerBg};
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    background: ${COLORS.headerBg};
    margin-bottom: 16px;
  }
`;

export const InputBox = styled.input`
  width: 100%;
  height: 48px;
  background: ${COLORS.white};
  box-sizing: border-box;
  border-right: none;
  border-radius: 0 ${(props) => props.theme.palette.borderRadius.b2}
    ${(props) => props.theme.palette.borderRadius.b2} 0;

  transition: 0.3s all ease-in-out;
  color: ${COLORS.black};
  @media (max-width: 1230px) {
    margin-top: 0;
    left: 0;
    // width: 200px;
  }
  &:focus {
    outline: none;
    // background: ${(props) => props.theme.palette.background.bg2};
    /* width: 50%; */
  }
  &:placeholder {
    color: #696e79;
  }
`;

export const SlashIconContainer = styled.div`
  display: flex;
  margin: auto;
  border-radius: ${(props) => props.theme.palette.borderRadius.b2} 0 0
    ${(props) => props.theme.palette.borderRadius.b2};
`;

export const SlashText = styled.div`
  border-radius: ${(props) => props.theme.palette.borderRadius.b2} 0 0
    ${(props) => props.theme.palette.borderRadius.b2};
  background: ${COLORS.white};
  height: 48px;
  width: 48px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchIconContainer = styled.div`
  cursor: pointer;
  background-color: ${COLORS.greyLight2};
  border-radius: 0 8px 8px 0;
  border: 1px solid ${COLORS.greyLight2};
  border-left: none;
  display: flex;
  align-items: center;
`;

interface SearchIconProps {
  displayAutocomplete: boolean;
}

export const SearchIconWrapper = styled.div<SearchIconProps>`
  background: ${(props) => (props.displayAutocomplete ? 'black' : 'white')};
  border-radius: 50%;
  padding: 2px;
  height: 32px;
  width: 32px;
  transition: 0.3s all ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

export const MobileInputAutocompleteContainer = styled.div`
  position: relative;
  @media (max-width: 1024px) {
    position: fixed;
    top: 69px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100 !important;
    overflow-y: scroll;
  }
`;

export const MobileSearchInput = styled.input`
  padding: 10px;
  &:focus {
    outline: none;
  }
  // color: black;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  z-index: 11;
  display: none;
  position: absolute;
  right: 0;
  top: 30%;
`;
