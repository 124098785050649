import React, { memo, useCallback, useContext, useEffect } from 'react';
import { MaskIcon } from '../Icons/Icons';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { openRlcSelectorRedux } from '../../redux/modalSlice';
import BrandLogo from '../BrandLogo/BrandLogo';
import {
  HeaderContainer,
  HeaderWrapper,
  IconContainer,
  LocalizationHeader,
  LocationContainer,
  LogoContainer,
  SiteHeader,
  UserPart,
} from './HeaderStyle';

import { Skeleton } from '@mui/material';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';
import Cookies from 'universal-cookie';
import { COLORS } from '../../constants/colors';
import { AppThemeContext } from '../../contexts/ThemeContext';
import { saveEventV3 } from '../../utils/eventTracking';

import ProductSearchInput from '../ProductSearchInput/ProductSearchInput';

import { languageList } from '../../constants/languageList';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { WE_USER_EVENT_THEME_TOGGLE } from '../../utils/we';
import CCFlag from '../CCFlag/CCFlag';
import SubHeader from '../SubHeader/SubHeader';
import HeaderCartIcon from './components/HeaderCartIcon';
import HeaderLoggedIn from './components/HeaderLoggedIn';
import HeaderLoggedOut from './components/HeaderLoggedOut';

const Link = dynamic(() => import('next/link'), {
  ssr: true,
});

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = React.useState<any>(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

interface HeaderProps {
  type?: string;
  headerColor?: 'translucent' | 'normal';
  children?: React.ReactElement;
  infinteHeader?: boolean;
  noSubHeader?: boolean;
}

const Header = (props: HeaderProps) => {
  const scrollDirection = useScrollDirection();
  const appTheme = useContext(AppThemeContext);
  const theme = useTheme();
  const router = useRouter();
  const { locale } = router;

  const dispatch = useAppDispatch();
  const { isLoggedIn, isFetching, user, loginStatus } = useAppSelector(
    (state) => state.user
  );

  const { cleverTap } = useAnalytics();

  const { locationDetails } = useAppSelector((state) => state.global);

  const handleThemeToggle = useCallback(() => {
    const cookies = new Cookies();
    if (appTheme?.theme === 'dark') {
      appTheme.changeTheme('light');
      cookies.set('theme', 'light', { maxAge: 1000000 });
    } else {
      appTheme?.changeTheme('dark');
      cookies.set('theme', 'dark', { maxAge: 1000000 });
    }
  }, [appTheme]);

  return (
    <HeaderWrapper>
      <HeaderContainer headerColor={props.headerColor ?? ''}>
        <SiteHeader infiniteHeader={props.infinteHeader ?? false}>
          <LogoContainer>
            <Link href="/" passHref>
              <a>
                <BrandLogo />
              </a>
            </Link>
          </LogoContainer>

          {props.type ? props.children : <ProductSearchInput type="web" />}

          <UserPart>
            {props.type === 'about' ? null : locationDetails?.country_code ? (
              <LocationContainer>
                <LocalizationHeader
                  onClick={() => {
                    saveEventV3({
                      category: 'header',
                      action: 'click',
                      label: 'rlc-button',
                      properties: '',
                      value: [],
                      from: router,
                    });
                    dispatch(openRlcSelectorRedux());
                  }}
                >
                  <CCFlag
                    countryCode={
                      locationDetails?.flag_country_code ||
                      locationDetails?.country_code
                    }
                    header={true}
                  />
                  {locationDetails.currency} &#x2022;{' '}
                  {languageList?.find((cur) => cur.locale === locale)?.name}
                </LocalizationHeader>
              </LocationContainer>
            ) : (
              <Skeleton height={70} width={70} />
            )}

            {props.type === 'about' ? null : (
              <IconContainer
                onClick={() => {
                  WE_USER_EVENT_THEME_TOGGLE(
                    {
                      previousTheme: theme.mode,
                      pageUrl: window.location.href,
                      currentTheme: theme.mode === 'dark' ? 'light' : 'dark',
                    },
                    cleverTap
                  );
                  saveEventV3({
                    category: 'header',
                    action: 'click',
                    label: 'theme',
                    properties: '',
                    value: [theme.mode === 'dark' ? 'light' : 'dark'],
                    from: router,
                    jsonData: {
                      old: theme.mode,
                      new: theme.mode === 'dark' ? 'light' : 'dark',
                    },
                  });
                  handleThemeToggle();
                }}
              >
                <MaskIcon
                  margin="0"
                  width="24px"
                  height="24px"
                  url={`/icons/${
                    appTheme?.theme === 'dark' ? 'moon' : 'star'
                  }.svg`}
                  selected={true}
                  color={COLORS.white}
                />
              </IconContainer>
            )}
            {
              // isLoggedIn &&
              user && (
                <>
                  <Link href="/favourites" passHref prefetch={false}>
                    <a aria-label="favourties icon">
                      <IconContainer
                        onClick={() => {
                          saveEventV3({
                            category: 'header',
                            action: 'click',
                            label: 'favourites',
                            properties: '',
                            value: ['/favourites'],
                            from: router,
                          });
                        }}
                      >
                        <MaskIcon
                          margin="0"
                          width="24px"
                          height="24px"
                          url="/icons/favorite-24.svg"
                          selected={true}
                          color={COLORS.white}
                        />
                      </IconContainer>
                    </a>
                  </Link>
                </>
              )
            }
            {props.type === 'cart' ||
            props.type === 'checkout' ? null : loginStatus === 'existing' &&
              user === undefined ? (
              <div>
                <IconContainer>
                  <Skeleton
                    height={'48px'}
                    width={'28px'}
                    sx={{ borderRadius: '50%' }}
                  />
                </IconContainer>
              </div>
            ) : (
              <>
                <Link href="/cart" passHref prefetch={false}>
                  <a aria-label="Cart icon">
                    <HeaderCartIcon />
                  </a>
                </Link>
              </>
            )}
            {props.type === 'cart' ||
            props.type === 'checkout' ? null : loginStatus === 'existing' &&
              user === undefined ? (
              <div style={{ display: 'flex', gap: '16px' }}>
                <Skeleton height={'48px'} width={'120px'} />
                <Skeleton height={'48px'} width={'120px'} />
              </div>
            ) : isLoggedIn ? (
              <HeaderLoggedIn />
            ) : (
              <HeaderLoggedOut />
            )}
          </UserPart>
        </SiteHeader>
      </HeaderContainer>
      {!(
        props.type === 'cart' ||
        props.type === 'checkout' ||
        props.type === 'user' ||
        props.type === 'about' ||
        props.type === 'sell-on-driffle' ||
        props.type === 'collections'
      ) &&
        !props.noSubHeader && <SubHeader scrollDirection={scrollDirection} />}
    </HeaderWrapper>
  );
};

export default memo(Header);
