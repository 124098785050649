import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { WIDTH } from '../../constants/screenResolution';
import { useLocale } from '../../contexts/LocalizationContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { saveEventV3 } from '../../utils/eventTracking';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { BackgroundIcon, MaskIcon } from '../Icons/Icons';

const SidebarMegamenu = dynamic(() => import('../SidebarMegamenu'), {
  ssr: false,
});

import dynamic from 'next/dynamic';
import Image from 'next/image';
import { setItmCookie } from '../../utils/itmTracking';
import {
  SubHeaderBg,
  SubHeaderContainer,
  SubHeaderContainerMenu,
  SubHeaderItem,
  SubHeaderWrapper,
} from './SubHeaderStyle';
import { SubOptionContainerI } from './modals';

const SubHeader = ({ scrollDirection }: { scrollDirection: any }) => {
  const { width } = useWindowDimensions();
  const router = useRouter();

  const { messages } = useLocale();
  const {
    membership_msg,
    categories_msg,
    store_msg,
    latest_games_msg,
    plus_save_with_plus_msg,
    upcoming_msg,
    topups_msg,
  } = messages || {};
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  const handleCloseSidebar = () => {
    setOpen(false);
  };

  const [subOptionContainer, setSubOptionContainer] =
    useState<SubOptionContainerI>({
      show: false,
      index: null,
      last: null,
    });

  const handleShowContainer = useCallback((index) => {
    setSubOptionContainer({
      show: true,
      index: index,
      last: index,
    });
  }, []);

  const handleCloseContainer = useCallback(() => {
    setSubOptionContainer((prev) => ({
      last: prev.index,
      show: false,
      index: null,
    }));
  }, []);

  useEffect(() => {
    const handleScroll = () => handleCloseContainer();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleCloseContainer]);

  const showSubMenuCount = useCallback(
    (data) => {
      if (width && width > getNumFromResolutionConstant(WIDTH.laptopMax))
        return 5;
      if (width && width > getNumFromResolutionConstant(WIDTH.tabletMax))
        return 3;
      return 0;
    },
    [width]
  );

  // const renderSubMenuItems = useCallback(() => {
  //   return navItems?.children.map(
  //     (data, index) =>
  //       data.subDivision &&
  //       data.subDivision
  //         .slice(0, showSubMenuCount(data.subDivision))
  //         .map((sectionData, index2) => (
  //           <OptionsSectionsV2
  //             key={index2}
  //             data={sectionData}
  //             index={index}
  //             parentSubMenu={data.name}
  //             onClose={handleCloseContainer}
  //             isShowing={
  //               subOptionContainer.show && index === subOptionContainer.last
  //             }
  //             display={subOptionContainer.last}
  //           />
  //         ))
  //   );
  // }, [navItems, showSubMenuCount, subOptionContainer, handleCloseContainer]);

  const handleCLickEvent = (
    values: string[],
    label: string,
    properties: string,
    campaign: string
  ) => {
    saveEventV3({
      action: 'click',
      category: 'subheader',
      label: label,
      value: values,
      properties: properties,
      from: router,
    });
    setItmCookie({
      source: 'subheader',
      medium: 'link',
      campaign: 'navbar-subheader',
      content: campaign || '',
    });
  };

  return (
    <>
      <SubHeaderWrapper
        style={{
          opacity: scrollDirection === 'down' ? 0 : 1,
          pointerEvents: scrollDirection === 'down' ? 'none' : 'inherit',
        }}
      >
        <SubHeaderBg />
        <SubHeaderContainer>
          <SubHeaderContainerMenu>
            {/* {navItems &&
            navItems?.children
              ?.filter((child: any) => child.navbar )
              ?.map((item: any, index: number) => {
                return item.name === 'save with plus' &&
                  (user.subscription === 'no' ||
                    user?.user?.storeDetails?.store_name) ? null : (
                  <Link
                    key={index}
                    passHref
                    href={
                      item.name
                        ? item.name === 'store'
                          ? item.url
                          : item.url?.includes('?')
                          ? '/store/' + item.name
                          : item.url ?? ''
                        : ''
                    }
                    prefetch={false}
                  >
                    <a>
                      <SubHeaderItem
                        selected={index === subOptionContainer.index}
                        onClick={() => {
                          if (item.url) {
                            saveEventV3({
                              category: 'header',
                              action: 'click',
                              label: 'sub-header-option',
                              properties: item.displayName,
                              value: [item.url],
                              from: router,
                            });
                          }
                        }}
                        onMouseLeave={() =>
                          item.subDivision && handleCloseContainer()
                        }
                        onMouseEnter={() =>
                          item.subDivision && handleShowContainer(index)
                        }
                      >
                        {item.name === 'save with plus'
                          ? user.subscription === 'active'
                            ? ''
                            : capitalizeFirstLetter(
                                item?.displayName?.replace('[--PLUS--]', '')
                              )
                          : item.displayName}
                        {item.subDivision && (
                          <MaskIcon
                            url="/icons/arrow-down-bold.svg"
                            height="18px"
                            width="18px"
                            margin="0"
                            color={COLORS.white}
                            selected={true}
                          />
                        )}
                        {item.name === 'steal deals' && (
                          <BackgroundIcon
                            height="24px"
                            width="24px"
                            margin="0 0 4px 4px"
                            url="/gifs/hot-trending.gif"
                          />
                        )}
                        {item.name === 'save with plus' && (
                          <BackgroundIcon
                            height="24px"
                            width="36px"
                            margin="0 5px 0 5px"
                            url="/logo/plus-logo.svg"
                          />
                        )}
                        {item.name === 'save with plus' &&
                        user.subscription === 'active'
                          ? membership_msg
                          : null}
                        <SubHeaderItemMarker
                          selected={index === subOptionContainer.index}
                        />
                      </SubHeaderItem>
                    </a>
                  </Link>
                );
              })} */}
            <SubHeaderItem
              onClick={() => {
                handleCLickEvent([], 'categories', '', 'categories');
                setOpen(true);
              }}
              style={{
                padding: '0',
                paddingRight: '8px',
              }}
              selected
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <MaskIcon
                  url="/icons/hamburger-black.svg"
                  width="18px"
                  height="18px"
                  margin="0"
                  selected
                  color="#fff"
                />
                {categories_msg}
              </div>
            </SubHeaderItem>
            <Link passHref href={'/store'} prefetch={false}>
              <a>
                <SubHeaderItem
                  onClick={() => {
                    handleCLickEvent(['Store'], 'sublink', '/store', 'store');
                  }}
                  selected
                >
                  {store_msg}
                </SubHeaderItem>
              </a>
            </Link>

            {/* <Link
              passHref
              href={'/store/collection/fortnite-skins'}
              prefetch={false}
            >
              <a>
                <SubHeaderItem
                  onClick={() => {
                    saveEventV3({
                      action: 'click',
                      category: 'subheader',
                      label: 'sublink',
                      value: ['Fortnite Skins'],
                      properties: '/store/collection/fortnite-skins',
                      from: router,
                    });
                    itmManager.setItmCookie({
                      source: 'navbar',
                      medium: 'navbar-option',
                      campaign: 'fortnite skins' || '',
                    });
                  }}
                  selected
                >
                  Fortnite Skins
                </SubHeaderItem>
              </a>
            </Link> */}
            <Link
              passHref
              href={'/store/collection/assassin-creed-games'}
              prefetch={false}
            >
              <a>
                <SubHeaderItem
                  onClick={() => {
                    handleCLickEvent(
                      ["Assassins' Creed"],
                      'sublink',
                      '/store/collection/assassin-creed-games',
                      'stalker 2'
                    );
                  }}
                  selected
                >
                  Assassins&apos; Creed
                </SubHeaderItem>
              </a>
            </Link>
            {/* <Link
              passHref
              href={'/store/collection/ea-sports-fc-game'}
              prefetch={false}
            >
              <a>
                <SubHeaderItem
                  onClick={() =>
                    saveEventV3({
                      action: 'click',
                      category: 'subheader',
                      label: 'sublink',
                      value: ['FC 25'],
                      properties: '/store/collection/ea-sports-fc-game',
                      from: router,
                    })
                  }
                  selected
                >
                  FC 25
                </SubHeaderItem>
              </a>
            </Link>
            <Link
              passHref
              href={'/store/collection/final-fantasy'}
              prefetch={false}
            >
              <a>
                <SubHeaderItem
                  onClick={() =>
                    saveEventV3({
                      action: 'click',
                      category: 'subheader',
                      label: 'sublink',
                      value: ['Final Fantasy XVI'],
                      properties: '/store/collection/final-fantasy',
                      from: router,
                    })
                  }
                  selected
                >
                  Final Fantasy XVI
                </SubHeaderItem>
              </a>
            </Link> */}
            {/* <Link
              passHref
              href={'/store/collection/frostpunk'}
              prefetch={false}
            >
              <a>
                <SubHeaderItem
                  onClick={() =>
                    saveEventV3({
                      action: 'click',
                      category: 'subheader',
                      label: 'sublink',
                      value: ['Frostpunk II'],
                      properties: '/store/collection/frostpunk',
                      from: router,
                    })
                  }
                  selected
                >
                  Frostpunk II
                </SubHeaderItem>
              </a>
            </Link> */}
            {/* <Link
              passHref
              href={'/store/collection/new-releases'}
              prefetch={false}
            >
              <a>
                <SubHeaderItem
                  onClick={() =>
                    saveEventV3({
                      action: 'click',
                      category: 'subheader',
                      label: 'sublink',
                      value: ['Latest Games'],
                      properties: '/store/collection/new-releases',
                      from: router,
                    })
                  }
                  selected
                >
                  {latest_games_msg}
                </SubHeaderItem>
              </a>
            </Link> */}
            <Link
              passHref
              href={'/store?preOrder=true&page=1'}
              prefetch={false}
            >
              <a>
                <SubHeaderItem
                  onClick={() => {
                    handleCLickEvent(
                      ['Pre-order'],
                      'sublink',
                      '/store?preOrder=true&page=1',
                      'pre-order'
                    );
                  }}
                  selected
                >
                  {upcoming_msg}
                </SubHeaderItem>
              </a>
            </Link>
            <Link passHref href={'/topup'} prefetch={false}>
              <a>
                <SubHeaderItem
                  onClick={() => {
                    handleCLickEvent(['Topups'], 'sublink', '/topup', 'topups');
                  }}
                  selected
                >
                  {topups_msg}
                </SubHeaderItem>
              </a>
            </Link>
            {/* <Link
              passHref
              href={'/store/collection/steal-deals'}
              prefetch={false}
            >
              <a>
                <SubHeaderItem
                  onClick={() =>
                    saveEventV3({
                      action: 'click',
                      category: 'subheader',
                      label: 'sublink',
                      value: ['Steal Deals'],
                      properties: '/store/collection/steal-deals',
                      from: router,
                    })
                  }
                  selected
                >
                  Steal Deals
                  <BackgroundIcon
                    height="24px"
                    width="24px"
                    margin="0 0 4px 4px"
                    url="/gifs/hot-trending.gif"
                  />
                </SubHeaderItem>
              </a>
            </Link> */}
            <Link passHref href={'/plus'} prefetch={false}>
              <a>
                <SubHeaderItem
                  onClick={() => {
                    handleCLickEvent(
                      ['Save with plus'],
                      'sublink',
                      '/plus',
                      'save with plus'
                    );
                  }}
                  selected
                >
                  {plus_save_with_plus_msg?.split('[--PLUS--]')[0]}

                  <BackgroundIcon
                    height="24px"
                    width="36px"
                    margin="0 5px 0 5px"
                    url="/logo/plus-logo.svg"
                  />
                </SubHeaderItem>
              </a>
            </Link>
            <Link passHref href={'/esim'} prefetch={false}>
              <a target="_blank">
                <SubHeaderItem
                  onClick={() =>
                    saveEventV3({
                      action: 'click',
                      category: 'subheader',
                      label: 'sublink',
                      value: ['Explore eSIMs'],
                      properties: '/esim',
                      from: router,
                    })
                  }
                  selected
                  style={{
                    display: 'flex',
                    gap: '4px',
                    borderLeft: '1.5px solid #ffffff1a',
                  }}
                >
                  <span>Explore eSIMs</span>
                  {/* <BackgroundIcon
                    height="24px"
                    width="36px"
                    margin="0 5px 0 5px"
                    url="/public"
                  /> */}
                  <Image
                    src="/icons/esim-icon.svg"
                    width="24px"
                    height="24px"
                    alt="Driffle ESims"
                    style={{ minWidth: '24px' }}
                  />
                </SubHeaderItem>
              </a>
            </Link>

            {/* <Link
              passHref
              href={'/store/collection/sale/black-friday-sale'}
              prefetch={false}
            >
              <a style={{ marginLeft: '16px', display: 'block' }}>
                <SubHeaderItem
                  onClick={() => {
                    handleCLickEvent(
                      ['Black Friday Sale'],
                      'sublink',
                      '/store/collection/sale/black-friday-sale',
                      'black-friday-sale'
                    );
                  }}
                  selected
                  style={{
                    background: '#000000',
                    height: '44px',
                  }}
                >
                  <Image
                    src="/icons/black-friday.svg"
                    width="176px"
                    height="10.75px"
                    alt="Black Friday Sale"
                  />
                </SubHeaderItem>
              </a>
            </Link> */}
          </SubHeaderContainerMenu>
          {/* <SubMenuSmallStrip /> */}
        </SubHeaderContainer>
        {/* <NavbarOptionContainer
        style={{
          display:
            subOptionContainer.show &&
            subOptionContainer.last !== null &&
            width &&
            width > getNumFromResolutionConstant(WIDTH.tabletMax)
              ? 'block'
              : 'none',
        }}
        index={subOptionContainer.last || 0}
        onMouseLeave={handleCloseContainer}
        onMouseEnter={() => handleShowContainer(subOptionContainer.last)}
      >
        <OptionsSection index={subOptionContainer.last || 0}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <OptionsItemChildrenContainer>
              {renderSubMenuItems()}
            </OptionsItemChildrenContainer>
          </div>
        </OptionsSection>
      </NavbarOptionContainer>
      <NavbarOverlay
        style={{
          display:
            subOptionContainer.show &&
            subOptionContainer.last !== null &&
            width &&
            width > getNumFromResolutionConstant(WIDTH.tabletMax)
              ? 'block'
              : 'none',
        }}
      /> */}
      </SubHeaderWrapper>
      {typeof window !== 'undefined' &&
        // open &&
        createPortal(
          <SidebarMegamenu open={open} handleClose={handleCloseSidebar} />,
          window.document.body
        )}
    </>
  );
};

export default memo(SubHeader);
